/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { create_user } from '../models/create_user';
import type { db_id } from '../models/db_id';
import type { email } from '../models/email';
import type { general_api_response_body } from '../models/general_api_response_body';
import type { token_response_body } from '../models/token_response_body';
import type { user } from '../models/user';
import type { password } from '../models/password';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { registerUserRequest } from '@/src/types';
export class AuthService {
  /**
   * Login with Email Password
   * Exchange a previously issued session ID plus XSRF token to assign that session ID to the provided user identified by the credentials. Note that this endpoint does not actually return auth related token or credentials. The session ID submitted just gets upgraded to be authenticated and should continue to be submitted for all future requests.
   *
   * @param requestBody Login credentials
   * @returns any Successfully logged in user
   * @throws ApiError
   */
  public static loginWithPassword(requestBody: {
    email: email;
    password: password;
    reCaptcha: string;
  }): CancelablePromise<
    general_api_response_body & {
      result?: user;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/login',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Change password of exisiting user
   * Take old password, new password and ID or user and change password of user. Note that if an admin is making the request, then old password is not required.
   *
   * @param requestBody User credentials
   * @returns any Successfully changed pasword of exisiting user
   * @throws ApiError
   */
  public static exsisitingUserChangePassword(requestBody: {
    oldPassword: password;
    newPassword: password;
    id: db_id;
  }): CancelablePromise<
    general_api_response_body & {
      result?: user;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/changePassword',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Logout user by unsetting all user data
   * Log out user by clearing out all the user data. Note that user does not need to be logged in to be logged out. The user data will be taken from the session and cleared out.
   *
   * @returns any Successfully logged out user
   * @throws ApiError
   */
  public static logout(): CancelablePromise<{
    /**
     * Boolean value of whether the API request was successful or not.
     */
    success: boolean;
    /**
     * Reason why API was successful or unsuccessful
     */
    message: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/logout',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Register new user
   * Submit user credentials in user fields object and register new user. Once user has been registered, they are logged in.
   *
   * @param requestBody User credentials
   * @returns any Successfully registered user
   * @throws ApiError
   */
  public static newUserRegister(
    reCaptcha: string,
    requestBody: registerUserRequest,
  ): CancelablePromise<general_api_response_body & token_response_body> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/register',
      body: {
        reCaptcha,
        userFields: requestBody,
      },
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Delete user under GDPR
   * Takes email address of user and deletes all data associated with that user after checking the request is coming from an admin or the user being deleted themselves. If user had GiftAid donations, user will need to be forcefully deleted.
   *
   * @param requestBody User credentials
   * @returns any Successfully deleted user
   * @throws ApiError
   */
  public static deleteUserGdpr(requestBody: {
    email: email;
    /**
     * A boolean value to force delete user if user has gift aid donations
     */
    force?: boolean;
  }): CancelablePromise<
    general_api_response_body & {
      result?: any[];
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/delete',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Submit support ticket to delete a user by ID
   * Takes ID of user and submits a delete request email to help centre. Note that this does not delete the user and only submits a request.
   *
   * @param cookie
   * @param xXsrfToken
   * @param dbId A unique ID corresponding to the entity in the database
   * @returns any Successfully sent support ticket to initiate delete for the user
   * @throws ApiError
   */
  public static submitSupportTicketDeleteUserById(
    cookie: string,
    xXsrfToken: string,
    dbId: number,
  ): CancelablePromise<
    general_api_response_body & {
      result?: any[];
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/deleteRequest/{dbId}',
      path: {
        dbId: dbId,
      },
      headers: {
        cookie: cookie,
        'X-XSRF-TOKEN': xXsrfToken,
      },
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Fetch current time
   * Fetch current time a the time of request
   *
   * @returns any Successfully fetched current time
   * @throws ApiError
   */
  public static getCurrentTime(): CancelablePromise<
    general_api_response_body & {
      result?: string;
    }
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/user/serverTime',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Register or log in user using facebook credentials
   * Register or log in a user by using facebook credentials. If user with facebook email existed before making the request, then user gets logged in, if not, then user will be registered. However, user will have to reset their password as a random pasword is generated for the user.
   *
   * @param requestBody User credentials
   * @returns any Successfully registered or logged in user
   * @throws ApiError
   */
  public static facebookUserRegisterOrLogin(requestBody: {
    verb: string;
    name?: string;
    profile_image_url?: string;
    id?: number;
    email?: email;
    firstName?: string;
    lastName?: string;
    token?: {
      access_token: string;
      expires: number;
      issued: number;
    };
  }): CancelablePromise<
    general_api_response_body & {
      result?: {
        redirect_url: string;
        token: string;
      };
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/social',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Send reset password email to user
   * Takes email of a valid user and sends reset password email to user. Note that user does not have to be signed in and anyone can send a reset password email to any other valid LaunchGood user using this endpoint.
   *
   * @param requestBody User credentials
   * @returns any Successfully sent a reset password to user
   * @throws ApiError
   */
  public static sendResetPassword(requestBody: {
    email: email;
  }): CancelablePromise<
    general_api_response_body & {
      result?: any[];
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/sendPasswordReset',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
}
