/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { general_api_response_body } from '../models/general_api_response_body';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ClubsService {
  /**
   * @deprecated
   * Load timeline of events for a club
   * Retrives all events in a club. A club is a a place where donors can join collectively to meet the challenges of the club, this can be daily giving or weekly giving or whatever the club's challenge is. The events in the club are donations that happened in the timeline of the club.
   *
   * @param clubId
   * @returns any Successfully loaded timeline of events in a club
   * @throws ApiError
   */
  public static clubsTimeline(clubId: number): CancelablePromise<
    general_api_response_body & {
      result?: Array<{
        summary: {
          DonationAmount: number;
          DonorCount: number;
          Date: string;
          ClubDay: number;
          CurrencyCode: string;
          CurrencySymbol: string;
        };
        items: Array<{
          type?: string;
          value?: string;
        }>;
      }>;
    }
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/clubs/timeline/{clubID}',
      path: {
        clubID: clubId,
      },
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
}
