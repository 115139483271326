/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { general_api_response_body } from '../models/general_api_response_body';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AdminService {
  /**
   * Fetch current supported currencies
   * Fetch currency codes of current supported currencies.
   *
   * @returns any Successfully fetched current supported currencies
   * @throws ApiError
   */
  public static fulladminCurrencies(): CancelablePromise<
    general_api_response_body & {
      result?: Array<string>;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/fulladmin/currencies',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
        500: `A PHP error was encountered`,
      },
    });
  }
}
