/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { general_api_response_body } from '../models/general_api_response_body';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { AllTimeStats } from '@/src/types';
export class HomeService {
  /**
   * Submit request to load home banners
   * Loads home banners
   * @returns any Successfully loaded home banners
   * @throws ApiError
   */
  public static homeBanners(): CancelablePromise<
    general_api_response_body & {
      result?: Array<{
        DestinationURL: string;
        NewWindow: boolean;
        ImageURL: string;
        OverlayText: null;
        ButtonText: null;
        Subtext: null;
      }>;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/home/banners',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Submit request to load home footer stats
   * Loads home footer stats
   * @returns any Successfully loaded home footer stats
   * @throws ApiError
   */
  public static footerStats(): CancelablePromise<
    general_api_response_body & {
      result?: AllTimeStats;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/home/footerStats', // this has changed on prod since the docs were written
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
}
