/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { search_discover_filter_property } from '../models/search_discover_filter_property';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SearchOrFilterService {
  /**
   * Search campaigns by keywords or filter by tags
   * The API provides campaigns search and filter mechanism. Campaigns can be searched by either keywords or by selecting specific filter options
   *
   * @param fArray `zakat_verified:true` will enable zakat only filter.
   *
   * `near_me:lat|long` will enable campaigns that are near your location
   * @param q Takes in search keywords to be filtered by this keyword. For example specifying "zakat" text will filter the campaigns by "zakat"
   *
   * @param randomize Randomizes search results. Values 'true' or 'false'
   *
   * @param limit limits result fetch upto the specified numbers
   *
   * @param offset Skips results by the number specified.
   *
   * @param src Specifies the search server environment. Can be either production or development
   *
   * @param type Specifies the type of search to commence
   *
   * @returns any Successfully fetch discover search results
   * @throws ApiError
   */
  public static searchOrFilterCampaigns(
    fArray?: string,
    q?: string,
    randomize: boolean = true,
    limit: number = 6,
    offset: number = 0,
    src:
      | 'launchgood-production'
      | 'launchgood-development' = 'launchgood-development',
    type: string = 'discovery',
  ): CancelablePromise<{
    filters: {
      uniq_nonprofit_type?: search_discover_filter_property;
      uniq_currency_code?: search_discover_filter_property;
      uniq_category?: search_discover_filter_property;
      uniq_zakat_verified?: search_discover_filter_property;
      uniq_featured?: search_discover_filter_property;
      uniq_country_code?: search_discover_filter_property;
    };
    max_score: number;
    projects: Array<{
      ID?: number;
      Name?: string;
      UserID?: number;
      Slug?: string;
      Tags?: string;
      PledgeAmount?: number;
      Goal?: number;
      CurrencyCode?: string;
      EndDate?: string;
      ModifiedDate?: number;
      Status?: number;
      FundingOption?: number;
      IsFeatured?: boolean;
      CurrencySymbol?: string;
      DonationCount?: number;
      Location?: string;
      FundedPercentage?: number;
      FundedPercentageCapped?: number;
      ImageURL?: string;
      UserImageURL?: string;
      UserName?: string;
      IsSponsored?: boolean;
      SponsorBannerURL?: string;
      SponsorName?: string;
      SponsorBannerClass?: string;
      CountryID?: string;
      URL?: string;
      ZakahEligible?: boolean;
      ZakatApproved?: boolean;
      Categories?: Array<string>;
      Type?: string;
      UserSupported?: boolean;
      DaysLeft?: number;
      Latitude?: null | string;
      Longitude?: null | string;
    }>;
    query: {
      category: boolean;
      filter: boolean;
      format: string;
      index: 'launchgood_local_project' | 'launchgood_production_project';
      limit: string;
      offset: string;
      param1: boolean;
      q: string;
      sponsorID: boolean;
      status: boolean;
      type: 'discovery';
      src: 'launchgood-development' | 'launchgood-production';
      sort: 'launchgood-development' | 'launchgood-production';
      randomize: string;
      f: Array<string>;
    };
    total_hits: {
      value?: number;
      relation?: string;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/',
      query: {
        'f[]': fArray,
        q: q,
        randomize: randomize,
        limit: limit,
        offset: offset,
        src: src,
        type: type,
      },
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
}
