/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { db_id } from '../models/db_id';
import type { donation_information } from '../models/donation_information';
import type { general_api_response_body } from '../models/general_api_response_body';
import type { project_api_donor_response } from '../models/project_api_donor_response';
import type { recent_donations } from '../models/recent_donations';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DonationService {
  /**
   * Load donations of exisiting user
   * Take ID of valid user and load information about the donations of the user.
   *
   * @param requestBody Information needed to request donation of a user
   * @returns any Successfully loaded donations of user
   * @throws ApiError
   */
  public static loadDonationsOfUser(requestBody: {
    id: db_id;
    offset?: number;
    limit?: number;
    filter?: {
      StartDate?: string;
      EndDate?: string;
    };
  }): CancelablePromise<
    general_api_response_body & {
      result?: donation_information;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/loadDonations',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Toggles zakat status of a user's donation
   * Toggles the zakat status to a campaign that the user donated to
   *
   * @param requestBody Includes the ID of the user
   * @returns any Successfully toggled zakat status of a donation to a campaign
   * @throws ApiError
   */
  public static toggleZakah(requestBody: { id: db_id }): CancelablePromise<
    general_api_response_body & {
      result?: {
        Zakah: boolean;
      };
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/projectapi/toggleZakat',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
        500: `A PHP error was encountered`,
      },
    });
  }
  /**
   * Fetch donor information of a campaign
   * Fetch the latest 20 donation information for a campaign. If the last id parameter is given, then load the 20 donations before the donation of the user id that corresponds to the last id parameter
   *
   * @param requestBody Includes the campaign id and the last donor id if provided
   * @returns any Successfully fetched donor information of a campaign
   * @throws ApiError
   */
  public static projectapiDonors(requestBody?: {
    id?: db_id;
    lastId?: number;
  }): CancelablePromise<
    general_api_response_body & {
      result?: project_api_donor_response;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/projectapi/donors',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
        500: `A PHP error was encountered`,
      },
    });
  }
  /**
   * Loads information about current donations of users
   * Returns information about donations. If initial is true or isn't given (default value is true), then returns the ten most recent donations that happened before the value given in the start param (current time if start param is null). If initial is false, then returns information about all donations that happend one minute before start time.
   *
   * @param requestBody Contains information about when to start querying the donations
   * @returns any Successfully loaded donations
   * @throws ApiError
   */
  public static loadDonationStream(requestBody: {
    /**
     * If true return only 10 donations, if false return donations that happened within a minute of start param. Default value true.
     *
     */
    initial?: boolean;
    /**
     * Unix timestamp from when to query donation information. Default value unix timestamp of current time.
     *
     */
    start?: number;
  }): CancelablePromise<
    general_api_response_body & {
      result?: recent_donations;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/donationStream/load',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Gets currency converstion rate between two currencies
   * Given two curreny codes in the path parameters,  returns the currency conversion rate for the two currencies.
   *
   * @param fromCurrencyCode
   * @param toCurrencyCode
   * @returns any Successfully fetched currency conversion rate between the two currenices
   * @throws ApiError
   */
  public static exchangeRate(
    fromCurrencyCode: string,
    toCurrencyCode: string,
  ): CancelablePromise<
    general_api_response_body & {
      result?: {
        rate: number;
        from: string;
        to: string;
      };
    }
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/project/data/exchange-rate/{fromCurrencyCode}/{toCurrencyCode}',
      path: {
        fromCurrencyCode: fromCurrencyCode,
        toCurrencyCode: toCurrencyCode,
      },
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
}
