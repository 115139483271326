/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { general_api_response_body } from '../models/general_api_response_body';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ZakatService {
  /**
   * Delete a user's zakat calculator profile
   * Delete the zakat calculator profile of the user that has the username provided in the request body. A zakat profile contains the zakat calculator data that the user used to calculate their zakat for a certain period.
   *
   * @param requestBody Includes user username to know which user to delete
   * @returns any Succesfully deleted zakat caluclator profile of user
   * @throws ApiError
   */
  public static deleteZakatCalculatorProfile(requestBody?: {
    username?: string;
    period?: number;
  }): CancelablePromise<
    general_api_response_body & {
      result?: boolean;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/zakat/deleteZakatCalculatorProfile',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Save a user's zakat calculator data
   * Takes user's zakat calculator data and saves the data in the database.
   *
   * @param requestBody Includes zakat calculator data
   * @returns any Successfully saved zakat calculator data
   * @throws ApiError
   */
  public static saveZakatCalculator(requestBody?: {
    username?: string;
    period?: number;
    entries?: {
      cash?: string;
      code?: string;
      goldGrams?: null | string;
      goldCash?: null | string;
      goldValue?: number;
      silverGrams?: null | string;
      silverCash?: null | string;
      silverValue?: number;
      activeInvestmentCash?: null | string;
      passiveInvestment?: Array<{
        passiveInvestmentStockName: null | string;
        passiveInvestmentStockValue: null | string;
      }>;
      dividendInvestment?: Array<{
        dividendInvestmentStockName: null | string;
        dividendInvestmentPerShare: null | string;
        dividendInvestmentStockAmount: null | string;
        dividendInvestmentValue: number;
      }>;
      expense?: Array<{
        expenseName: string;
        expenseCash: string;
        expenseFrequency: null | string;
      }>;
      cryptoCurrencyInvestmentCash?: null | string;
      pensionInvestmentCash?: null | string;
      propertyInvestmentCash?: null | string;
      propertyRentalIncome?: null | string;
      propertyRentalExpenses?: null | string;
      debtCash?: string;
      otherAssetCash?: null | string;
      profile?: string;
      totalAssets?: number;
      totalDebts?: number;
      zakatAnniversary?: string;
      zakatStartDate?: string;
      zakatDelta?: number;
      zakatDue?: number;
    };
  }): CancelablePromise<
    general_api_response_body & {
      result?: boolean;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/zakat/saveCalculatorData',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Edit and save a user's zakat calculator data
   * Edit zakat calculator data and save it
   *
   * @param requestBody Includes edited zakat calculator data
   * @returns any Successfully edited and saved zakat calculator data
   * @throws ApiError
   */
  public static editZakatCalculator(requestBody?: {
    username?: string;
    period?: number;
    entries?: {
      cash?: string;
      code?: string;
      goldGrams?: null | string;
      goldCash?: null | string;
      goldValue?: number;
      silverGrams?: null | string;
      silverCash?: null | string;
      silverValue?: number;
      activeInvestmentCash?: null | string;
      passiveInvestment?: Array<{
        passiveInvestmentStockName: null | string;
        passiveInvestmentStockValue: null | string;
      }>;
      dividendInvestment?: Array<{
        dividendInvestmentStockName: null | string;
        dividendInvestmentPerShare: null | string;
        dividendInvestmentStockAmount: null | string;
        dividendInvestmentValue: number;
      }>;
      expense?: Array<{
        expenseName: string;
        expenseCash: string;
        expenseFrequency: null | string;
      }>;
      cryptoCurrencyInvestmentCash?: null | string;
      pensionInvestmentCash?: null | string;
      propertyInvestmentCash?: null | string;
      propertyRentalIncome?: null | string;
      propertyRentalExpenses?: null | string;
      debtCash?: string;
      otherAssetCash?: null | string;
      profile?: string;
      totalAssets?: number;
      totalDebts?: number;
      zakatAnniversary?: string;
      zakatStartDate?: string;
      zakatDelta?: number;
      zakatDue?: number;
    };
  }): CancelablePromise<
    general_api_response_body & {
      result?: boolean;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/zakat/editCalculatorData',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Save offline zakat donation
   * Take offline zakat donation and save it.
   *
   * @param requestBody Includes information about offline zakat donation
   * @returns any Succesfully added offline donation
   * @throws ApiError
   */
  public static saveZakatOfflineDonation(requestBody?: {
    username?: string;
    period?: number;
    entries?: {
      cash: number;
      code: string;
      date: string;
      title: string;
      notes: string;
      donationType: string;
      location: string;
    };
  }): CancelablePromise<
    general_api_response_body & {
      result?: boolean;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/zakat/saveOfflineDonation',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Fetch user's zakat profiles
   * Fetch all zakat profiles for the user that corresponds to the username provided in the request body. A zakat profile contains the zakat calculator data that the user used to calculate their zakat for a certain period
   *
   * @param requestBody Includes user username to know which user to fetch zakat profiles for
   * @returns any Succesfully fetched zakat profiles
   * @throws ApiError
   */
  public static getAllZakatProfiles(requestBody?: {
    username?: string;
  }): CancelablePromise<
    general_api_response_body & {
      result?: Array<{
        period?: string;
        cash?: string;
        code?: string;
        goldGrams?: null | string;
        goldCash?: null | string;
        goldValue?: string;
        silverGrams?: null | string;
        silverCash?: null | string;
        silverValue?: string;
        activeInvestmentCash?: null | string;
        passiveInvestmentStockName?: null | string;
        passiveInvestmentStockValue?: null | string;
        dividendInvestmentStockName?: null | string;
        dividendInvestmentPerShare?: null | string;
        dividendInvestmentStockAmount?: null | string;
        dividendInvestmentValue?: string;
        expenseName?: string;
        expenseCash?: string;
        expenseFrequency?: null | string;
        cryptoCurrencyInvestmentCash?: null | string;
        pensionInvestmentCash?: null | string;
        propertyInvestmentCash?: null | string;
        propertyRentalIncome?: null | string;
        propertyRentalExpenses?: null | string;
        debtCash?: string;
        otherAssetCash?: null | string;
        profile?: string;
        totalAssets?: string;
        totalDebts?: string;
        zakatAnniversary?: string;
        zakatStartDate?: string;
        zakatDelta?: string;
        zakatDue?: string;
      }>;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/zakat/getAllProfiles',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Fetch current gold and silver prices
   * Fetch current metal prices. These are gold and silver prices as they are on the date that this request is called.
   *
   * @returns any Succesfully fetched gold and silver prices
   * @throws ApiError
   */
  public static fetchMetalPrices(): CancelablePromise<
    general_api_response_body & {
      result?: {
        GoldPrice: string;
        SilverPrice: string;
      };
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/zakat/fetchMetalPrices',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Fetches zakat profile of a user
   * Fetches a zakat profile of a user. This profile contains the zakat calculator data that the user used to calculate their zakat for a period.
   *
   * @param requestBody Includes data to indentify which profile to fetch
   * @returns any Successfully fetched zakat profile
   * @throws ApiError
   */
  public static getZakatProfile(requestBody?: {
    username?: string;
    profile?: string;
  }): CancelablePromise<
    general_api_response_body & {
      result?: {
        period?: string;
        passiveInvestment?: Array<{
          passiveInvestmentStockName: null | string;
          passiveInvestmentStockValue: null | string;
        }>;
        dividendInvestment?: Array<{
          dividendInvestmentStockName: null | string;
          dividendInvestmentPerShare: null | string;
          dividendInvestmentStockAmount: null | string;
          dividendInvestmentValue: string;
        }>;
        expense?: Array<{
          expenseName: string;
          expenseCash: string;
          expenseFrequency: null | string;
        }>;
        cash?: string;
        code?: string;
        goldGrams?: null | string;
        goldCash?: null | string;
        goldValue?: string;
        silverGrams?: null | string;
        silverCash?: null | string;
        silverValue?: string;
        activeInvestmentCash?: null | string;
        cryptoCurrencyInvestmentCash?: null | string;
        pensionInvestmentCash?: null | string;
        propertyInvestmentCash?: null | string;
        propertyRentalIncome?: null | string;
        propertyRentalExpenses?: null | string;
        debtCash?: string;
        otherAssetCash?: null | string;
        profile?: string;
        totalAssets?: string;
        totalDebts?: string;
        zakatAnniversary?: string;
        zakatStartDate?: string;
        zakatDelta?: string;
        zakatDue?: string;
      };
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/zakat/getZakatProfile',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
}
