/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { general_api_response_body } from '../models/general_api_response_body';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class StripeService {
  /**
   * @deprecated
   * Fetch all supported stripe countries
   * Fetch information about countries that Stripe  can work with. The information provided includes the name and code of the country.
   *
   * @returns any Successfully fetched all Stripe supported countries
   * @throws ApiError
   */
  public static stripeSupportedCountries(): CancelablePromise<
    general_api_response_body & {
      result?: Array<{
        Name: string;
        Code: string;
      }>;
    }
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/stripe/supportedCountries',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
}
