/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { general_api_response_body } from '../models/general_api_response_body';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ProjectAdminService {
  /**
   * Check if payment information is valid for a campaign
   * Check if payment information is valid for a campaign. This request takes the id of the campaign in the path.
   *
   * @param projectId
   * @returns any Successfully checked if payment information is valid for a campaign or not
   * @throws ApiError
   */
  public static paymentInfoValid(projectId: number): CancelablePromise<
    general_api_response_body & {
      result?: {
        state?: string;
      };
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/projectadmin/paymentInfoValid/{projectID}',
      path: {
        projectID: projectId,
      },
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Fetch basic info of a campaign
   * Fetch basic info of a campaign. This includes information such as the slug of the campaign and the id of the payment account associated with the campaign.
   *
   * @returns any Successfully fetched basic info of a campaign.
   * @throws ApiError
   */
  public static projectAdminBasicInfo(): CancelablePromise<
    general_api_response_body & {
      result?: {
        slug?: string;
        tabs?: Array<{
          id?: string;
          status?: string;
        }>;
        application?: string;
        payment_type?: string;
        account_id?: number;
        canEdit?: boolean;
      };
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/projectadmin/basicInfo',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
}
