/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { count_query_items_result } from '../models/count_query_items_result';
import type { db_id } from '../models/db_id';
import type { general_api_response_body } from '../models/general_api_response_body';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import {
  CampaignStatus,
  campaignAdminBasicInfoResponse,
  CampaignAdminBarInfo,
} from '@/src/types';
export class CampaignService {
  /**
   * Add, remove, list, or order user favorite campaigns
   * Depending on the verb given in the query paremeter, this API endpoint either adds, removes, lists or orders user favorite campaigns.
   *
   * @param requestBody Request information
   * @returns any Successfully added, removed, or listed user favorite projects
   * @throws ApiError
   */
  public static userFavorite(
    requestBody:
      | {
          verb: string;
          project_id: db_id;
          source: string;
        }
      | {
          verb: string;
          project_id: db_id;
        }
      | {
          verb: string;
        }
      | {
          verb: string;
          suggestedListId: db_id;
        },
  ): CancelablePromise<
    general_api_response_body & {
      result: {
        favorited: boolean;
      };
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/favorite',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Figure out if user has donated to a campaign or not
   * Take IDs of campaigns and figures out if user has donated to the campaigns or not.
   *
   * @param requestBody Campaign IDs
   * @returns any Successfully figured out if user has donated to a campaign or not
   * @throws ApiError
   */
  public static cardsSupportInfo(requestBody: {
    ids: Array<db_id>;
  }): CancelablePromise<
    general_api_response_body & {
      result?: CampaignStatus[];
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/cards/supportInfo',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Load user's number of campaigns
   * Take ID of user and loads the number of campaigns user has or is part of a team member for or draft along with the total number of overall campaigns user is involved with.
   *
   * @param requestBody User credentials
   * @returns any Successfully loaded user's number of campaigns
   * @throws ApiError
   */
  public static cardsCountQueryItems(requestBody: {
    id: db_id;
  }): CancelablePromise<
    general_api_response_body & {
      result?: count_query_items_result;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/cards/countQueryItems',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Fetch information about all banned countries
   * Fetch information about all banned countries such as name, ISO3166, flags and ID.
   *
   * @returns any Successfully fetched informmation about all banned countries
   * @throws ApiError
   */
  public static bannedCountryAllInfo(): CancelablePromise<
    general_api_response_body & {
      result?: Array<{
        Name: string;
        ISO3166: string | null;
        Flags: number;
        ID: number;
      }>;
    }
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/projectapi/bannedCountryAllInfo',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Fetch information about all ofac countries
   * Fetch information about all ofac countries such as name, ISO3166, flags and ID.
   *
   * @returns any Successfully fetched informmation about all ofac countries
   * @throws ApiError
   */
  public static ofacCountryAllInfo(): CancelablePromise<
    general_api_response_body & {
      result?: Array<{
        Name: string;
        ISO3166: string | null;
        Flags: number;
        ID: number;
      }>;
    }
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/projectapi/ofacCountryAllInfo',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Fetch banned country codes
   * Fetch banned country codes for all banned countries
   * @returns any Successfully fetched banned country codes
   * @throws ApiError
   */
  public static bannedCountryCodes(): CancelablePromise<
    general_api_response_body & {
      result?: Array<string>;
    }
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/projectapi/bannedCountrCodes',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Fetch OFAC country codes
   * Fetch OFAC country codes for all OFAC countries
   * @returns any Successfully fetched OFAC country codes
   * @throws ApiError
   */
  public static ofacCountryCodes(): CancelablePromise<
    general_api_response_body & {
      result?: Array<string>;
    }
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/projectapi/ofacCountryCodes',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Fetch banned country names
   * Fetch banned country names for all banned countries
   * @returns any Successfully fetched banned country names
   * @throws ApiError
   */
  public static bannedCountryNames(): CancelablePromise<
    general_api_response_body & {
      result?: Array<string>;
    }
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/projectapi/bannedCountryNames',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Fetch OFAC country names
   * Fetch OFAC country codes for all OFAC countries
   * @returns any Successfully fetched OFAC country names
   * @throws ApiError
   */
  public static ofacCountryNames(): CancelablePromise<
    general_api_response_body & {
      result?: Array<string>;
    }
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/projectapi/ofacCountryNames',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Fetch all supported currency codes
   * Fetches all supported currency codes along with their symbols, minimum amounts, and suggested amounts.
   *
   * @param requestBody Specifies a type object which can only be of value 'project' but is not necessary.
   * @returns any Successfully fetched all supported currency codes
   * @throws ApiError
   */
  public static currencyCodes(requestBody: {
    type?: string;
  }): CancelablePromise<
    general_api_response_body & {
      result?: Array<{
        code: string;
        symbol: string;
        minimumAmount: number;
        suggestedAmounts: Array<number>;
      }>;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/projectapi/currencyCodes',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Add new banned country
   * Takes data about new country and adds new country to the banned country list.
   *
   * @param requestBody Information about new country to be banned
   * @returns any Successfully added country to banned country list
   * @throws ApiError
   */
  public static addNewBannedCountry(requestBody: {
    bannedCountryData: {
      Name: string;
      ISO3166?: string;
      isOFAC?: boolean;
    };
  }): CancelablePromise<
    general_api_response_body & {
      result?: any[];
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/projectapi/addNewBannedCountry',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
        500: `A PHP error was encountered`,
      },
    });
  }
  /**
   * Delete banned country
   * Takes ID of banned country and removes banned country from the banned country list.
   *
   * @param requestBody Information about country to be deleted
   * @returns any Successfully deleted country from banned country list
   * @throws ApiError
   */
  public static deleteBannedCountry(requestBody: {
    id: db_id;
  }): CancelablePromise<
    general_api_response_body & {
      result?: any[];
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/projectapi/deleteBannedCountry',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
        500: `A PHP error was encountered`,
      },
    });
  }
  /**
   * Fetch all country levels
   * Fetch security levels of all countries in the world. Country levels indicate the risk factor associated with allowing funds to be sent to the specific country. The lower the country level value, the lower the risk.
   *
   * @returns any Successfully fetched levels of all countries
   * @throws ApiError
   */
  public static countryLevels(): CancelablePromise<
    general_api_response_body & {
      result?: Array<{
        CountryCode: string;
        FieldValue: string;
      }>;
    }
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/projectapi/countryLevels',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Edit banned country
   * Takes object containing banned country data and edits banned country.
   *
   * @param requestBody Information about country to be edited
   * @returns any Successfully edited banned country
   * @throws ApiError
   */
  public static editBannedCountry(requestBody: {
    bannedCountryData: {
      Name: string;
      ISO3166: string;
      Flags: string;
      ID: db_id;
      isOfac?: boolean;
    };
  }): CancelablePromise<
    general_api_response_body & {
      result?: any[];
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/projectapi/editbannedCountry',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
        500: `A PHP error was encountered`,
      },
    });
  }
  /**
   * Likes a campaign
   * Likes the campaign corresponding to the  ID provided in the path parameter
   *
   * @param id
   * @returns any Successfully liked a campaign
   * @throws ApiError
   */
  public static projectLike(id: db_id): CancelablePromise<
    general_api_response_body & {
      result?: {
        liked: boolean;
      };
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/projectapi/like/{id}',
      path: {
        id: id,
      },
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Increment share counter of user
   * Increments share counter of a user. This can be when a user shares a campaign through facebook, twitter or email. This API restricts that a user cannot share a campaign more than twice.
   *
   * @param dbId A unique ID corresponding to the entity in the database
   * @param requestBody Includes the method of sharing that the user chose
   * @returns any Successfully incremented user share counter
   * @throws ApiError
   */
  public static shareCounter(
    dbId: number,
    requestBody?: {
      type?: string;
    },
  ): CancelablePromise<
    general_api_response_body & {
      result?:
        | {
            shareCounter: number;
            type: string;
          }
        | Array<null>
        | {
            shareCounter: number;
            project: string;
            null: boolean;
          };
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/projectapi/shareCounter/{dbId}',
      path: {
        dbId: dbId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
        500: `A PHP error was encountered`,
      },
    });
  }
  /**
   * Check if payment information is valid for a campaign
   * Check if payment information is valid for a campaign. This request takes the id of the campaign in the path.
   *
   * @param projectId
   * @returns any Successfully checked if payment information is valid for a campaign or not
   * @throws ApiError
   */
  public static paymentInfoValid(projectId: number): CancelablePromise<
    general_api_response_body & {
      result?: {
        state?: string;
      };
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/projectadmin/paymentInfoValid/{projectID}',
      path: {
        projectID: projectId,
      },
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Fetch basic info of a campaign
   * Fetch basic info of a campaign. This includes information such as the slug of the campaign and the id of the payment account associated with the campaign.
   *
   * @returns any Successfully fetched basic info of a campaign.
   * @throws ApiError
   */
  public static projectAdminBasicInfo(projectId: number): CancelablePromise<
    general_api_response_body & {
      result?: campaignAdminBasicInfoResponse;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/projectadmin/basicInfo',
      body: {
        id: projectId,
      },
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }

  public static projectBarInfo(id: number): CancelablePromise<
    general_api_response_body & {
      result: CampaignAdminBarInfo;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/projectadmin/projectBarInfo',
      body: {
        id,
      },
    });
  }

  /**
   * Set zakat status of a campaign
   * Set zakat status of a campaign. This request requires that the user making the request is a valid user and is an admin.
   *
   * @param requestBody Includes id of the user, status to set the zakat status of the campaign and a reason which is only provided if the status is rejected.
   * @returns any Succesfully set the zakat status of a campaign
   * @throws ApiError
   */
  public static projectAdminZakatStatus(requestBody?: {
    id?: db_id;
    status?: string;
    reason?: string;
  }): CancelablePromise<
    general_api_response_body & {
      result?: {
        status?: string;
      };
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/projectadmin/zakatStatus',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Fetch admin tab information for a campaign
   * Fetch admin tab information for a campaign. This request requires that the user making the request is a valid user and is an admin.
   *
   * @param requestBody Includes id of the user
   * @returns any Succesfully fetched admin section information of a campaign
   * @throws ApiError
   */
  public static projectAdminTabInfo(requestBody?: {
    id?: db_id;
  }): CancelablePromise<
    general_api_response_body & {
      result?: {
        ID?: string;
        Name?: string;
        Goal?: string;
        Creator?: string;
        CreatorEmail?: string;
        PledgeAmount?: string;
        DonorCount?: string;
        Status?: string;
        StatusText?: string;
        EndDate?: string;
        Slug?: string;
        ManagerUsername?: null;
        ManagerID?: string;
        AddedDate?: string;
        LaunchDate?: string;
        CurrencySymbol?: string;
        NoteCount?: number;
        LastContactedDate?: null | string;
        Sponsors?: any[];
        IsFeatured?: string;
        FeeVersion?: string;
        FreshDesk?: null | string;
        FeeInformation?: {
          TransactionUnit?: number;
          TransactionRate?: number;
          ApplicationRate?: number;
          ApplicationUnit?: number;
          OfflineApplicationRate?: number;
          OfflineApplicationUnit?: number;
        };
        isCampaignHide?: boolean;
        isCampaignHideForNonAdmin?: boolean;
        isZakat?: string;
        zakatStatus?: string;
        zakatStatuses?: Array<{
          label?: string;
          order?: number;
          ID?: string;
        }>;
        FOC?: Array<{
          id?: string;
          isCommunity?: boolean;
          name?: string;
          relativeUrl?: string;
        }>;
        Coaching?: string;
        HideHelping?: boolean;
        Flags?: string;
        InsightsCustomMessage?: null | string;
        SubmittedDate?: string;
      };
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/projectadmin/adminTabInfo',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Create a campaign in the version 2 process
   * Creates a campaign in the version 2 process. This request requires that the user making the request is a valid user and is the user creating the campaign.
   *
   * @param requestBody Includes the information required to create a campaign
   * @returns any Succesfully created a campaign
   * @throws ApiError
   */
  public static createProjectV2(requestBody?: {
    params?: {
      id?: string;
      name?: string;
      type?: string;
      countryFundraiser?: {
        Code?: string;
        Name?: string;
      };
      display_name?: string;
      optionType?: string;
      incomplete?: boolean;
      accountOwner?: boolean;
      beneficiaryName?: string;
      countryBeneficiary?: string;
      canGoLive?: boolean;
      title: string;
      goal: string;
      currencycode?: string;
      countryCode: string;
      campaign_for?: string;
    };
  }): CancelablePromise<
    general_api_response_body & {
      result?: {
        editUrl?: string;
        projectId?: string;
      };
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/projectadmin/createProjectV2',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Fetch bank account types
   * Fetches currently supported bank account types.
   *
   * @returns any Succesfully fetched bank account types
   * @throws ApiError
   */
  public static bankAccountTypes(): CancelablePromise<
    general_api_response_body & {
      result?: Array<{
        name?: string;
        type?: string;
      }>;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/projectadmin/bankAccountTypes',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
}
