export interface Config {
  env: string;
  buiderIoApiKey: string;
  builderIoApiBaseUrl: string;
  baseUrl: string;
  supportUrl: string;
  blogUrl: string;
  lg3BaseUrl: string;
  lg4ApiBaseUrl: string;
  gtmId: string;
  lg3OpenSearchUrl: string;
  reCaptchaSiteKey: string;
  freshDeskChatToken?: string;
  facebookAppId: string;
  googleClientId: string;
  googlePayMerchantId: string;
  googlePayEnv: string;
  firebase: {
    collection: string;
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
  };
  googleMapsApiKey: string;
}

export default function config(): Config {
  // general
  const env = process.env.NODE_ENV || 'production';
  // Builder.io
  const buiderIoApiKey =
    process.env.NEXT_PUBLIC_BUILDER_API_KEY ||
    'c05b786f1645447ab878b73ca4dd6870';
  const builderIoApiBaseUrl =
    process.env.NEXT_PUBLIC_BUILDER_API_BASE_URL ||
    'https://cdn.builder.io/api/V3';

  // this application
  const baseUrl =
    process.env.NEXT_PUBLIC_BASE_URL || 'https://www.launchgood.com/v4';

  // support URL
  const supportUrl =
    process.env.NEXT_PUBLIC_SUPPORT_URL || 'https://support.launchgood.com';

  const blogUrl =
    process.env.NEXT_PUBLIC_BLOG_URL || 'https://blog.launchgood.com';

  // REST API configuration
  const lg3BaseUrl =
    process.env.NEXT_PUBLIC_LG3_BASE_URL || 'https://www.launchgood.com';
  const lg4ApiBaseUrl =
    process.env.NEXT_PUBLIC_LG4_API_BASE_URL || 'https://api.launchgood.com';

  // Google Tag Manager
  const gtmId = process.env.NEXT_PUBLIC_GTM_ID || 'GTM-M8J85T9';

  const lg3OpenSearchUrl =
    process.env.NEXT_PUBLIC_LG3_OPEN_SEARCH_URL ||
    'https://v3.launchgood.com/v3';
  // Googpl Pay ID
  const googlePayMerchantId =
    process.env.NEXT_PUBLIC_GOOGLE_MERCHANT_ID || 'BCR2DN4TQDV4PPA5';
  const googlePayEnv =
    process.env.NEXT_PUBLIC_GOOGLEPAY_ENVIRONMENT || 'PRODUCTION';

  //reCAPTCHA
  const reCaptchaSiteKey =
    process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY ||
    '6LcPeL4ZAAAAALW8Fppbnh41cQUrw742AvnyZx4u';

  const freshDeskChatToken = process.env.NEXT_PUBLIC_FRESH_DESK_TOKEN;
  const facebookAppId =
    process.env.NEXT_PUBLIC_FACEBOOK_APP_ID || '575274599245723';
  const googleClientId =
    process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID ||
    '646522442205-876360o26d51knp4nb8tr4d5fv24jaji.apps.googleusercontent.com';
  const firebase = {
    collection: process.env.NEXT_PUBLIC_FIREBASE_COLLECTION || 'comments-dev',
    apiKey:
      process.env.NEXT_PUBLIC_FIREBASE_API_KEY ||
      'AIzaSyDaSR8t63AVANbTqnLUwGex52_OqbgeW8o',
    authDomain:
      process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN ||
      'lg4-frontend.firebaseapp.com',
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID || 'lg4-frontend',
    storageBucket:
      process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET ||
      'lg4-frontend.appspot.com',
    messagingSenderId:
      process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID || '465134486779',
    appId:
      process.env.NEXT_PUBLIC_FIREBASE_APP_ID ||
      '1:465134486779:web:611d4057e3367849d69c5e',
  };
  const googleMapsApiKey =
    process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY ||
    'AIzaSyDQBFrER26yh-tTlHSRPIEJDiPxEVjpLN8';

  return {
    env,
    buiderIoApiKey,
    builderIoApiBaseUrl,
    baseUrl,
    supportUrl,
    blogUrl,
    lg3BaseUrl,
    lg4ApiBaseUrl,
    gtmId,
    lg3OpenSearchUrl,
    reCaptchaSiteKey,
    freshDeskChatToken,
    facebookAppId,
    googleClientId,
    googlePayMerchantId,
    googlePayEnv,
    firebase,
    googleMapsApiKey,
  };
}
