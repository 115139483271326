/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { general_api_response_body } from '../models/general_api_response_body';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AccountService {
  /**
   * Fetch payment accounts of user
   * Fetch payment accounts of user.
   *
   * @returns any Succesfully fetched payment account of users
   * @throws ApiError
   */
  public static accountForProjectByUser(): CancelablePromise<
    general_api_response_body & {
      result?: {
        accounts?: Array<{
          id?: string;
          name?: string;
          type?: string;
          countryFundraiser?: {
            Code: string;
            Name: string;
          };
          display_name?: string;
          optionType?: string;
          incomplete?: boolean;
          accountOwner?: boolean;
          beneficiaryName?: string;
          countryBeneficiary?: string;
          canGoLive?: boolean;
        }>;
      };
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/account/ForProjectByUserId',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
}
