'use client';

import { OpenAPI } from './client';
import { ApiRequestOptions } from './client/core/ApiRequestOptions';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getToken = async (options: ApiRequestOptions): Promise<string> => {
  // TODO: figure out a way to make this workable on serer-side so we don't need 'use client'
  if (!window.localStorage) {
    throw new Error('No local storage available');
  }
  const token = window.localStorage.getItem('token') || '';
  return Promise.resolve(token);
};

OpenAPI.TOKEN = getToken;
export * from './client';
