/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { general_api_response_body } from '../models/general_api_response_body';
import type { recent_donations } from '../models/recent_donations';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class NewsfeedService {
  /**
   * Loads information about current donations of users
   * Returns information about donations. If initial is true or isn't given (default value is true), then returns the ten most recent donations that happened before the value given in the start param (current time if start param is null). If initial is false, then returns information about all donations that happend one minute before start time.
   *
   * @param requestBody Contains information about when to start querying the donations
   * @returns any Successfully loaded donations
   * @throws ApiError
   */
  public static loadDonationStream(requestBody: {
    /**
     * If true return only 10 donations, if false return donations that happened within a minute of start param. Default value true.
     *
     */
    initial?: boolean;
    /**
     * Unix timestamp from when to query donation information. Default value unix timestamp of current time.
     *
     */
    start?: number;
  }): CancelablePromise<
    general_api_response_body & {
      result?: recent_donations;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/donationStream/load',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
}
