/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { address } from '../models/address';
import type { community_data } from '../models/community_data';
import type { count_query_items_result } from '../models/count_query_items_result';
import type { create_user } from '../models/create_user';
import type { current_user_result } from '../models/current_user_result';
import type { db_id } from '../models/db_id';
import type { donation_information } from '../models/donation_information';
import type { email } from '../models/email';
import type { general_api_response_body } from '../models/general_api_response_body';
import type { password } from '../models/password';
import type { save_profile_api_response_user_information } from '../models/save_profile_api_response_user_information';
import type { save_user_api_response_user_information } from '../models/save_user_api_response_user_information';
import type { token_response_body } from '../models/token_response_body';
import type { user } from '../models/user';
import type { user_data } from '../models/user_data';
import type { user_information } from '../models/user_information';
import type { user_profile } from '../models/user_profile';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UserService {
  /**
   * Login with Email Password
   * Exchange a previously issued session ID plus XSRF token to assign that session ID to the provided user identified by the credentials. Note that this endpoint does not actually return auth related token or credentials. The session ID submitted just gets upgraded to be authenticated and should continue to be submitted for all future requests.
   *
   * @param requestBody Login credentials
   * @returns any Successfully logged in user
   * @throws ApiError
   */
  public static loginWithPassword(requestBody: {
    email: email;
    password: password;
  }): CancelablePromise<
    general_api_response_body & {
      result?: user;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/login',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Change password of exisiting user
   * Take old password, new password and ID or user and change password of user. Note that if an admin is making the request, then old password is not required.
   *
   * @param requestBody User credentials
   * @returns any Successfully changed pasword of exisiting user
   * @throws ApiError
   */
  public static exsisitingUserChangePassword(requestBody: {
    oldPassword: password;
    newPassword: password;
    id: db_id;
  }): CancelablePromise<
    general_api_response_body & {
      result?: user;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/changePassword',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Logout user by unsetting all user data
   * Log out user by clearing out all the user data. Note that user does not need to be logged in to be logged out. The user data will be taken from the session and cleared out.
   *
   * @returns any Successfully logged out user
   * @throws ApiError
   */
  public static logout(): CancelablePromise<{
    /**
     * Boolean value of whether the API request was successful or not.
     */
    success: boolean;
    /**
     * Reason why API was successful or unsuccessful
     */
    message: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/logout',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Register new user
   * Submit user credentials in user fields object and register new user. Once user has been registered, they are logged in.
   *
   * @param requestBody User credentials
   * @returns any Successfully registered user
   * @throws ApiError
   */
  public static newUserRegister(
    requestBody: create_user,
  ): CancelablePromise<general_api_response_body & token_response_body> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/register',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Delete user under GDPR
   * Takes email address of user and deletes all data associated with that user after checking the request is coming from an admin or the user being deleted themselves. If user had GiftAid donations, user will need to be forcefully deleted.
   *
   * @param requestBody User credentials
   * @returns any Successfully deleted user
   * @throws ApiError
   */
  public static deleteUserGdpr(requestBody: {
    email: email;
    /**
     * A boolean value to force delete user if user has gift aid donations
     */
    force?: boolean;
  }): CancelablePromise<
    general_api_response_body & {
      result?: any[];
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/delete',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Submit support ticket to delete a user by ID
   * Takes ID of user and submits a delete request email to help centre. Note that this does not delete the user and only submits a request.
   *
   * @param cookie
   * @param xXsrfToken
   * @param dbId A unique ID corresponding to the entity in the database
   * @returns any Successfully sent support ticket to initiate delete for the user
   * @throws ApiError
   */
  public static submitSupportTicketDeleteUserById(
    cookie: string,
    xXsrfToken: string,
    dbId: number,
  ): CancelablePromise<
    general_api_response_body & {
      result?: any[];
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/deleteRequest/{dbId}',
      path: {
        dbId: dbId,
      },
      headers: {
        cookie: cookie,
        'X-XSRF-TOKEN': xXsrfToken,
      },
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Sign up for newsletter or campaign page updates
   * Sign up user for newsletter or campaign page updates after validating that user email formal is correct. Note that email address does not necessarily have to belong to an exisiting user, as long as email format is valid, API should be successful
   *
   * @param requestBody User information
   * @returns any Successfully signed up for updates
   * @throws ApiError
   */
  public static countMeIn(requestBody: {
    email: email;
    type?: string;
  }): CancelablePromise<{
    /**
     * Boolean value of whether the API request was successful or not.
     */
    success: boolean;
    /**
     * Reason why API was successful or unsuccessful
     */
    message: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/countMeIn',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Fetch current time
   * Fetch current time a the time of request
   *
   * @returns any Successfully fetched current time
   * @throws ApiError
   */
  public static getCurrentTime(): CancelablePromise<
    general_api_response_body & {
      result?: string;
    }
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/user/serverTime',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Register or log in user using facebook credentials
   * Register or log in a user by using facebook credentials. If user with facebook email existed before making the request, then user gets logged in, if not, then user will be registered. However, user will have to reset their password as a random pasword is generated for the user.
   *
   * @param requestBody User credentials
   * @returns any Successfully registered or logged in user
   * @throws ApiError
   */
  public static facebookUserRegisterOrLogin(requestBody: {
    verb: string;
    name?: string;
    profile_image_url?: string;
    id?: number;
    email?: email;
    first_name?: string;
    last_name?: string;
  }): CancelablePromise<
    general_api_response_body & {
      result?: {
        redirect_url: string;
        token: string;
      };
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/social',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Resend Donation Receipt over Email
   * Resend User's donation receipt over an email medium
   *
   * @param requestBody Donation ID
   * @returns any Successfully resent donation receipt over email And an empty array will be shown
   *
   * @throws ApiError
   */
  public static resendReceipt(requestBody: {
    /**
     * A valid donation ID, made by the user of the same token
     *
     */
    id: number;
  }): CancelablePromise<any[]> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/resendReceipt',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Result body with error message`,
      },
    });
  }
  /**
   * Toggle subscribe to newsletter for valid users
   * Subsribe or unsubsribe a user to newsletter. If user was already subsribed, simply unsubsribe them. However if user was not subsribed, check if user's email is confirmed and if so subsribe to newsletter only and if user's email was not confirmed, then subsribe user to newsletter and email confirmation.
   *
   * @param cookie
   * @param xXsrfToken
   * @param dbId A unique ID corresponding to the entity in the database
   * @returns any Successfully toggled subscribe to newsletter button
   * @throws ApiError
   */
  public static toggleSubsribeToNewsletter(
    cookie: string,
    xXsrfToken: string,
    dbId: number,
  ): CancelablePromise<
    general_api_response_body & {
      result?: any[];
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/toggleSubsribeToNewsletter/{dbId}',
      path: {
        dbId: dbId,
      },
      headers: {
        cookie: cookie,
        'X-XSRF-TOKEN': xXsrfToken,
      },
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Toggle dipslay name of user on recent donations
   * Toggle display of user's name on recent donation
   * @param cookie
   * @param xXsrfToken
   * @param dbId A unique ID corresponding to the entity in the database
   * @returns any Successfully toggled display of user's name on recent donation
   * @throws ApiError
   */
  public static toggleDisplayNameOnRecentDonations(
    cookie: string,
    xXsrfToken: string,
    dbId: number,
  ): CancelablePromise<
    general_api_response_body & {
      result?: any[];
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/toggleDisplayNameOnRecentDonations/{dbId}',
      path: {
        dbId: dbId,
      },
      headers: {
        cookie: cookie,
        'X-XSRF-TOKEN': xXsrfToken,
      },
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Toggle display of supported campaigns for valid user
   * Toggles display of the supported campaigns of a user
   *
   * @param cookie
   * @param xXsrfToken
   * @param dbId A unique ID corresponding to the entity in the database
   * @returns any Successfully toggled display of supported campaigns for valid user
   * @throws ApiError
   */
  public static toggleDisplaySupportedCampaigns(
    cookie: string,
    xXsrfToken: string,
    dbId: number,
  ): CancelablePromise<
    general_api_response_body & {
      result?: any[];
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/toggleDisplaySupportedCampaigns/{dbId}',
      path: {
        dbId: dbId,
      },
      headers: {
        cookie: cookie,
        'X-XSRF-TOKEN': xXsrfToken,
      },
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Toggle display of communities for valid user
   * Toggles display of created communities of a user
   *
   * @param cookie
   * @param xXsrfToken
   * @param dbId A unique ID corresponding to the entity in the database
   * @returns any Successfully toggled display of communities for valid user
   * @throws ApiError
   */
  public static toggleDisplayCommunities(
    cookie: string,
    xXsrfToken: string,
    dbId: number,
  ): CancelablePromise<
    general_api_response_body & {
      result?: any[];
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/toggleDisplayCommunities/{dbId}',
      path: {
        dbId: dbId,
      },
      headers: {
        cookie: cookie,
        'X-XSRF-TOKEN': xXsrfToken,
      },
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Toggle display of created campaigns for valid user
   * Toggles display of the created campaigns of a user
   *
   * @param cookie
   * @param xXsrfToken
   * @param dbId A unique ID corresponding to the entity in the database
   * @returns any Successfully toggled display of created campaigns of user
   * @throws ApiError
   */
  public static toggleDisplayCreatedCampaigns(
    cookie: string,
    xXsrfToken: string,
    dbId: number,
  ): CancelablePromise<
    general_api_response_body & {
      result?: any[];
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/toggleDisplayCreatedCampaigns/{dbId}',
      path: {
        dbId: dbId,
      },
      headers: {
        cookie: cookie,
        'X-XSRF-TOKEN': xXsrfToken,
      },
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Load donations of exisiting user
   * Take ID of valid user and load information about the donations of the user.
   *
   * @param requestBody Information needed to request donation of a user
   * @returns any Successfully loaded donations of user
   * @throws ApiError
   */
  public static loadDonationsOfUser(requestBody: {
    id: db_id;
    offset?: number;
    limit?: number;
    filter?: {
      StartDate?: string;
      EndDate?: string;
    };
  }): CancelablePromise<
    general_api_response_body & {
      result?: donation_information;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/loadDonations',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Load zakat eligible donations of exisiting user
   * Take ID of valid user and load information about the zakat eligible donations of the user.
   *
   * @param requestBody Information needed to request zakat eligible donations of a user
   * @returns any Successfully loaded zakat eligible donations of user
   * @throws ApiError
   */
  public static loadZakatEligibleDonationsOfUser(requestBody: {
    id: db_id;
    offset?: number;
    limit?: number;
    filter?: {
      StartDate?: string;
      EndDate?: string;
    };
  }): CancelablePromise<
    general_api_response_body & {
      result?: donation_information;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/loadZakatEligibleDonations',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Load communities owned by a user
   * Gets a list of communities that are owned by the user
   *
   * @param requestBody Request information
   * @returns any Successfully loaded communities under a user
   * @throws ApiError
   */
  public static loadCommunityData(requestBody: {
    id: db_id;
    offset?: number;
    limit?: number;
  }): CancelablePromise<
    general_api_response_body & {
      result?: community_data;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/loadCommunityData',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Load information about a user
   * Take username of user and load information about the user
   *
   * @param requestBody User credentials
   * @returns any Successfully loaded information about a user
   * @throws ApiError
   */
  public static loadUser(requestBody: {
    /**
     * Username of user
     */
    name: string;
  }): CancelablePromise<
    general_api_response_body & {
      result?: user_information;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/loadUser',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Save default settings of a valid user
   * Submit user ID and data and save default settings of user.
   *
   * @param requestBody User credentials
   * @returns any Successfully saved default settings on a user
   * @throws ApiError
   */
  public static saveDefaultSetting(requestBody: {
    user: db_id;
    data?: {
      cover_fees: string;
      gift_aid: string;
      subscribe_updates: string;
      defaultDisplayName: boolean;
      defaultDisplayLocation: boolean;
      custom_name: boolean;
    };
  }): CancelablePromise<
    general_api_response_body & {
      result?: any[];
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/saveDefaultSetting',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Save user profile settings
   * Take user credentials and save profile settings of an exisiting user.
   *
   * @param requestBody User credentials
   * @returns any Successfully saved profile settings of user
   * @throws ApiError
   */
  public static saveUserProfile(requestBody: {
    userData: user_data;
    profile: user_profile;
  }): CancelablePromise<
    general_api_response_body & {
      result?: save_profile_api_response_user_information;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/saveProfile',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Subscribe to all updates from all donations of a user
   * Submit a request to subscribe to all updates from all donations of a user
   *
   * @param requestBody User credentials
   * @returns any Successfully subscribed to all updates for all donations of a user
   * @throws ApiError
   */
  public static subscribeAll(requestBody: { userID: db_id }): CancelablePromise<
    general_api_response_body & {
      result?: any[];
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/subscribeAll',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Unsubscribe from all updates from all donations of a user
   * Submit a request to unsubscribe from all updates from all donations of a user
   *
   * @param requestBody User credentials
   * @returns any Successfully unsubscribed from all updates for all donations of a user
   * @throws ApiError
   */
  public static unsubscribeAll(requestBody: {
    userID: db_id;
  }): CancelablePromise<
    general_api_response_body & {
      result?: any[];
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/unsubscribeAll',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Validate email of non registered user
   * Takes email of a user and checks if user is registered in our database or not. API call fails if user already exists since user email is valid if email already exists in the database. However, if email doesn't exist, then API call lets us know if the provided email is a valid email format or not.
   *
   * @param requestBody User credentials
   * @returns any Successfully validated email
   * @throws ApiError
   */
  public static validateEmail(requestBody: {
    userData: user_data;
  }): CancelablePromise<
    general_api_response_body & {
      result?: any[];
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/validateEmail',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Save user new email
   * Take ID and email of user and save new email of user if email provided in request is different that the email associated with the ID of the user.
   *
   * @param requestBody User credentials
   * @returns any Successfully saved new email of user
   * @throws ApiError
   */
  public static saveUser(requestBody: {
    userData: user_data;
  }): CancelablePromise<
    general_api_response_body & {
      result?: save_user_api_response_user_information;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/saveUser',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Clear profile picture of valid user
   * Take ID of valid user and clear the profile picture of the user.
   *
   * @param requestBody User credentials
   * @returns any Successfully cleared profile picture of user
   * @throws ApiError
   */
  public static clearProfilePictureOfUser(requestBody: {
    userID: db_id;
  }): CancelablePromise<
    general_api_response_body & {
      result?: any[];
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/clearProfilePicture',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Load, save, or clear user shipping address
   * Load, save or clear user shipping address depending on the method given to the API in the request body.
   *
   * @param requestBody User information
   * @returns any Successfully cleared, loaded or saved user shipping address
   * @throws ApiError
   */
  public static userShippingAddress(requestBody: {
    /**
     * Signifies if request is load, save or clear.
     */
    method: 'load' | 'save' | 'clear';
    user: db_id;
    /**
     * Only required if method is save or clear
     */
    address?: address;
  }): CancelablePromise<
    general_api_response_body & {
      result?: {
        shippingAddress: address;
        billingAddress: address;
        addresses: address[];
      };
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/userShippingAddress',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Create guest user account
   * Creates a guest user account when a user is making a donation without loggin in. First checks if user has an account with the email the donation is made in and if that's the case, then the donation will be added to the exisiting user account. However, if no user account exists with the given email, the a guest account is created.
   *
   * @param requestBody User credentials
   * @returns any Successfully created a guest account for the user
   * @throws ApiError
   */
  public static createGuestUser(requestBody: {
    verb: string;
    email: email;
    name: string;
  }): CancelablePromise<
    general_api_response_body & {
      result?: {
        id: number;
        type: string;
        hash: string;
      };
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/guest',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Update enrollments of valid user
   * Update enrollment subscriptions of user
   *
   * @param requestBody User credentials
   * @returns any Successfully updated enrollments of user
   * @throws ApiError
   */
  public static updateEnrollments(requestBody: {
    verb: string;
    userID: db_id;
    cardID?: db_id;
    subscriptions: Array<{
      SubscriptionType?: string;
      ClubOrChallengeID?: db_id;
    }>;
  }): CancelablePromise<
    general_api_response_body & {
      result?: any[];
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/updateEnrollments',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Send reset password email to user
   * Takes email of a valid user and sends reset password email to user. Note that user does not have to be signed in and anyone can send a reset password email to any other valid LaunchGood user using this endpoint.
   *
   * @param requestBody User credentials
   * @returns any Successfully sent a reset password to user
   * @throws ApiError
   */
  public static sendResetPassword(requestBody: {
    email: email;
    isGuest?: boolean;
  }): CancelablePromise<
    general_api_response_body & {
      result?: any[];
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/sendPasswordReset',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Load or save user giving enrollment information
   * Loads or saves user giving enrollment information based on the value of verb in the query parameter.
   *
   * @param requestBody Request information
   * @returns any Successfully loaded or saved user giving enrollment information
   * @throws ApiError
   */
  public static loadOrSaveGivingEnrollment(requestBody: {
    userId: db_id;
    verb?: string;
    ramadan?: string;
    dhc?: string;
    friday_givers?: string;
    only_for?: string;
  }): CancelablePromise<
    general_api_response_body & {
      result?: Array<{
        type?: string;
        value?: string;
        date?: string;
      }>;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/giving-enrollment',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Add, remove, list, or order user favorite campaigns
   * Depending on the verb given in the query paremeter, this API endpoint either adds, removes, lists or orders user favorite campaigns.
   *
   * @param requestBody Request information
   * @returns any Successfully added, removed, or listed user favorite projects
   * @throws ApiError
   */
  public static userFavorite(
    requestBody:
      | {
          verb: string;
          project_id: db_id;
          source: string;
        }
      | {
          verb: string;
          project_id: db_id;
        }
      | {
          verb: string;
        }
      | {
          verb: string;
          suggestedListId: db_id;
        },
  ): CancelablePromise<
    general_api_response_body & {
      result?:
        | {
            favorited: boolean;
          }
        | {
            giving_project_ids: Array<number>;
            you_may_like_project_ids: Array<number>;
            supported_project_ids: Array<number>;
            closed_project_ids: Array<number>;
          };
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/favorite',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Submit request to retrieve total, recent or friend points for a user.
   * Retrives total, recent or friend point of a user depending on the verb givne in the query paremeter.
   *
   * @param requestBody Request information
   * @returns any Successfully retrieved total, recent or friend points for a user.
   * @throws ApiError
   */
  public static userPoints(
    requestBody:
      | {
          verb: string;
          userId: db_id;
          source?: string;
        }
      | {
          verb: string;
          userId: db_id;
          limit?: number;
        }
      | {
          verb: string;
          query: {
            source?: string;
            limit?: number;
            offset?: number;
          };
        }
      | {
          verb: string;
          userId: db_id;
          limit?: number;
          source: string;
        },
  ): CancelablePromise<
    general_api_response_body & {
      result?: any[] | number;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/points',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Loads information about the current user
   * Loads information about the user making the request
   * @returns any Successfully loaded information about the current user
   * @throws ApiError
   */
  public static currentUser(): CancelablePromise<
    general_api_response_body & {
      result?: current_user_result;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/currentUser',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Figure out if user has donated to a campaign or not
   * Take IDs of campaigns and figures out if user has donated to the campaigns or not.
   *
   * @param requestBody Campaign IDs
   * @returns any Successfully figured out if user has donated to a campaign or not
   * @throws ApiError
   */
  public static cardsSupportInfo(requestBody: {
    ids: Array<db_id>;
  }): CancelablePromise<
    general_api_response_body & {
      result?: Array<{
        buttonText: string;
        enabled: boolean;
        id: number;
        favorited: boolean;
        allowFavorite: boolean;
        supported?: boolean;
      }>;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/cards/supportInfo',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Load user's number of campaigns
   * Take ID of user and loads the number of campaigns user has or is part of a team member for or draft along with the total number of overall campaigns user is involved with.
   *
   * @param requestBody User credentials
   * @returns any Successfully loaded user's number of campaigns
   * @throws ApiError
   */
  public static cardsCountQueryItems(requestBody: {
    id: db_id;
  }): CancelablePromise<
    general_api_response_body & {
      result?: count_query_items_result;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/cards/countQueryItems',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Increment share counter of user
   * Increments share counter of a user. This can be when a user shares a campaign through facebook, twitter or email. This API restricts that a user cannot share a campaign more than twice.
   *
   * @param dbId A unique ID corresponding to the entity in the database
   * @param requestBody Includes the method of sharing that the user chose
   * @returns any Successfully incremented user share counter
   * @throws ApiError
   */
  public static shareCounter(
    dbId: number,
    requestBody?: {
      type?: string;
    },
  ): CancelablePromise<
    general_api_response_body & {
      result?:
        | {
            shareCounter: number;
            type: string;
          }
        | Array<null>
        | {
            shareCounter: number;
            project: string;
            null: boolean;
          };
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/projectapi/shareCounter/{dbId}',
      path: {
        dbId: dbId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
        500: `A PHP error was encountered`,
      },
    });
  }
}
