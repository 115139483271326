/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { club_or_challenge_information } from '../models/club_or_challenge_information';
import type { general_api_response_body } from '../models/general_api_response_body';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class GivingService {
  /**
   * Load information about a club or challenge
   * Retrives all information about a club or a challenge. This is a large response containing all the information about a club or a challenge.
   *
   * @param type Type of load. Can either be club or challenge.
   * @param slug Slug of challenge or club
   * @returns any Successfully loaded information about the club or challenge
   * @throws ApiError
   */
  public static givingLoad(
    type: string,
    slug: string,
  ): CancelablePromise<
    general_api_response_body & {
      result?: club_or_challenge_information;
    }
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/giving/load/{type}/{slug}',
      path: {
        type: type,
        slug: slug,
      },
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
}
