/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { admin_section_response } from '../models/admin_section_response';
import type { community_details } from '../models/community_details';
import type { community_permission_response } from '../models/community_permission_response';
import type { general_api_response_body } from '../models/general_api_response_body';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CommunityPageService {
  /**
   * Get details of a community page
   * Get details of a community page by slug. This request get information like the id of the community page, the ids of the campaigns under the community page and much more.
   *
   * @param requestBody Includes slug of the community page
   * @returns any Succesfully created a community page
   * @throws ApiError
   */
  public static communityDetailBySlug(requestBody?: {
    slug?: string;
  }): CancelablePromise<
    general_api_response_body & {
      result?: community_details;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/communitypage/DetailBySlug',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
        500: `A PHP error was encountered`,
      },
    });
  }
  /**
   * Get id of a community page
   * Get id of a community page by the slug. Even though community/DetailBySlug API also gets the id of a community page, this API is much faster.
   *
   * @param requestBody Includes the slug of the community page
   * @returns any Succesfully fetched id of a community page
   * @throws ApiError
   */
  public static communityidBySlug(requestBody?: {
    slug?: string;
  }): CancelablePromise<
    general_api_response_body & {
      result?: {
        sponsor_id: number;
      };
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/communitypage/idBySlug',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Get permissions of a community page
   * Get permissions of a community page by slug.
   *
   * @param requestBody Includes slug of the community page
   * @returns any Succesfully fetched permissions of communtiy page
   * @throws ApiError
   */
  public static communityPermissions(requestBody?: {
    slug?: string;
  }): CancelablePromise<
    general_api_response_body & {
      result?: community_permission_response;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/communitypage/communtiy-permissions',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
        500: `A PHP error was encountered`,
      },
    });
  }
  /**
   * Fetch team information of a community page
   * Fetch team information of a community page
   *
   * @param requestBody Includes slug of the community page
   * @returns any Succesfully fetched team information of communtiy page
   * @throws ApiError
   */
  public static communityPageTeam(requestBody?: {
    slug?: string;
    method?: string;
  }): CancelablePromise<
    general_api_response_body & {
      result?: {
        team: Array<{
          id: number;
          name: string;
          email: string;
          permission: null;
        }>;
        invites: any[];
      };
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/communitypage/team',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Fetch admin section information of community page
   * Takes the slug of the community page and loads the admin section information of a community page. This request requires that the user making the request is a valid user and is an admin.
   *
   * @param requestBody Includes slug of the community page
   * @returns any Succesfully fetched admin section information of community page
   * @throws ApiError
   */
  public static communityAdminSection(requestBody?: {
    slug?: string;
    method?: string;
  }): CancelablePromise<
    general_api_response_body & {
      result?: admin_section_response;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/communitypage/adminSection',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Gets campaigns of community page.
   * Gets campaigns of community page or approves or rejects a campaign request to join a community page.
   *
   * @param requestBody Includes information to dictate what the request will do.
   * @returns any Successfully got campaigns under community page or approved or rejected a campaign to be part of a community page.
   * @throws ApiError
   */
  public static userCardRemove(
    requestBody?:
      | {
          slug?: string;
          method?: string;
        }
      | {
          slug?: string;
          method?: string;
          id?: number;
          status?: string;
          reason?: string;
        }
      | {
          slug?: string;
          method?: string;
          id?: number;
          status?: string;
        },
  ): CancelablePromise<
    general_api_response_body & {
      result?: Array<{
        Creator?: string;
        CreatorEmail?: string;
        PledgeAmount?: string;
        DonorCount?: string;
        Status?: string;
        StatusText?: string;
        AddedDate?: string;
        EndDate?: string;
        LaunchDate?: null | string;
        ID?: string;
        Name?: string;
        Slug?: string;
        URL?: string;
        ProjectID?: string;
        SponsorID?: string;
        SPStatus?: string;
        ApprovalFlags?: string;
        RejectFlags?: string;
        Flags?: string;
        CurrencySymbol?: string;
        Goal?: number;
        displayType?: string;
      } | null>;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/communitypage/getCampaigns',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Fetch communities of a user
   * Fetch communities of a user
   *
   * @param requestBody Includes id of the user
   * @returns any Succesfully fetched communities of a user
   * @throws ApiError
   */
  public static communityListByUser(requestBody?: {
    id?: number;
    method?: string;
  }): CancelablePromise<
    general_api_response_body & {
      result?: {
        total?: number;
        items?: Array<{
          ID?: number;
          Valid?: boolean;
          Name?: string;
          Created?: string;
          Updated?: string;
          Banner?: null | string;
          Description?: null | string;
          Slug?: string;
          Budget?: string;
          ManagerID?: null | string;
          BannerClass?: null | string;
          LogoPath?: null | string;
          MailerliteListID?: null | string;
          ParentProjectID?: null | string;
          Color?: null | string;
          RelativeURL?: null | string;
          Flags?: number;
          Status?: number;
        }>;
      };
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/communitypage/communityListByUser',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Toggle featured status of a community page
   * Toggle featured status of a community page. This request requires that the user making the request is a valid user and is an admin.
   *
   * @param requestBody Includes id of the admin user
   * @returns any Succesfully toggled featured status of community page
   * @throws ApiError
   */
  public static communityToggleFeatured(requestBody?: {
    id?: number;
  }): CancelablePromise<
    general_api_response_body & {
      result?: {
        IsFeatured: boolean;
      };
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/communitypage/toggleFeatured',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Deletes default image of a community page
   * Deletes default image of a community page
   *
   * @param requestBody Includes id of the community page
   * @returns any Succesfully deleted default image of a community page
   * @throws ApiError
   */
  public static communityDeleteDefaultImage(requestBody?: {
    id?: number;
  }): CancelablePromise<
    general_api_response_body & {
      result?: Array<null>;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/communitypage/deleteDefaultImage',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Check url of community page
   * Check url of community page
   *
   * @param requestBody Includes id and url of the community page to be checked
   * @returns any Succesfully checked url of community page
   * @throws ApiError
   */
  public static communityCheckUrl(requestBody?: {
    id?: number;
    method?: string;
    url?: string;
  }): CancelablePromise<
    general_api_response_body & {
      result?: Array<null>;
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/communitypage/urlCheck',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Fetch sponsor stats of a community page
   * Fetch sponsor stats of a community page.
   *
   * @param requestBody Includes slug of the community page
   * @returns any Succesfully fetched sponsor stats of community page
   * @throws ApiError
   */
  public static communitySponsorStats(requestBody?: {
    slug?: string;
    currency?: string;
  }): CancelablePromise<
    general_api_response_body & {
      result?: {
        stats: {
          projectCount: number;
          patronCount: number;
          fundedAmount: number;
          currency: string;
        };
      };
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/communitypage/sponsorStats',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Create a community page
   * Creates a community page for a user and returns the slug of the community page.
   *
   * @param requestBody Includes the title and currency code of the community page
   * @returns any Succesfully created a community page
   * @throws ApiError
   */
  public static createCommunityPage(requestBody?: {
    title?: string;
    currencyCode?: string;
  }): CancelablePromise<
    general_api_response_body & {
      result?: {
        editUrl: string;
      };
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/communitypage/createCommunity',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
  /**
   * Archive a community page
   * Archive a community page. This request requires that the user making the request is a valid user and an admin.
   *
   * @param requestBody Includes the id of the community page to be archived
   * @returns any Succesfully archived a community page
   * @throws ApiError
   */
  public static communityArchive(requestBody?: {
    id?: number;
  }): CancelablePromise<
    general_api_response_body & {
      result?: any[];
    }
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/communitypage/archive',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
}
